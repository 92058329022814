/*
 * @Date: 2023-02-07 11:33:51
 * @LastEditors: simLarry
 * @LastEditTime: 2023-03-16 17:01:20
 * @FilePath: \F1-M3-QRP-CODE\pages\_app.js
 */
import App from 'next/app';
import Head from 'next/head'

import React from 'react';


import { ConfigProvider } from 'antd';

import '$DATA/Less/merge.less';

export default class MyApp extends App {
	render(){
		const { Component, pageProps } = this.props;
		return (
			<>
				<Head>
					<title>Fun88 - Trang Thể Thao và Giải Trí Hàng Đầu Châu Á</title>

				</Head>

				<ConfigProvider>
					<Component { ...pageProps } />
				</ConfigProvider>
			</>
		)
	}
}